import React from 'react'
import about from '../assets/images/about.jpg'
const Intro = (props) => {
  return (
	<section className="about_section layout_padding">
    <div className="container mt-2 ">
      <div className="row align-item-center">
        <div className="col-md-6 ">
          <div className="img-box text-center">
            <img src={about} alt=""></img>
            {/* <p className='fs-5 fw-bold mt-2'>Dr. Diwakar Bhagat</p>
            <p className='text-secondary'>Chairman,IRG GP Saharsa</p> */}
          </div>
        </div>
        <div className="col-md-5">
          <div className="detail-box my-auto">
            <div className="heading_container mt-3">
              <h2>
                Welcome To Our <span>College</span>
              </h2>
            </div>
              {props.info?<p>{props.info}</p>:<p>Government Polytechnic, Saharsa is a AICTE approved technical institute, established under the Government of Bihar. G.P.Saharsa is one of the best polytechnics in Bihar since 1966 and produced many technocrats and alumni from the all part of the state in service for society and contribution for the Nation Building.</p>}
            <a href={props.href?props.href:'/about'} target={props.target?props.target:""}>
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Intro