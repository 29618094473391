import React,{useEffect,useState} from 'react'
import { Container,Row,Col ,Form,FormGroup,Label,Input,Button} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const Contact = () => {
	useEffect(() => {
		var mapProp = {
			center: new window.google.maps.LatLng(25.882963157571783, 86.60800534232787),
			zoom: 18,
		};
		var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
}, [])
const [state,setState]=useState({
  name:'',
  email:'',
  mobileNo:'',
  text:''
  })
  const history=useNavigate();

  // const diffToast=(err)=>{
  // toast.error(err,{
  //   position:'top-right'
  // })
  // }

  const ChangeHandler =(e)=> {
  const value=e.target.value;
  setState({...state, [e.target.name]:value});
  console.log(state)
}
  const SubmitHandler =async (e)=>{
  setState({err:[]});
  e.preventDefault();
  const data={
    "name":`${state.name}`,
    "email":`${state.email}`,
    "MobileNo":`${state.mobileNo}`,
    "text":`${state.text}`
  }
  await axios.post(`https://my-college-app.herokuapp.com/api/query/`,data)
  .then(res=>{
    window.alert("sent succesfully");
  }).catch(err=>{
    // diffToast(err.response.data.errors[0].msg);
  });
  }
  return (
	<section className="contact_section layout_padding2">

      <Container>
        <Container className="heading_container">
          <h2>
            Contact Us
          </h2>
        </Container>
        <Row>
          <Col md={6}>
            <div className="form_container">
              <Form onSubmit={(e)=>SubmitHandler(e)} >
					<Row className="justify-content-center ">
						<FormGroup>
						<Input type="name" name="name" id="exampleName" className='py-1'  placeholder='name' onChange={(e)=>ChangeHandler(e)} />
						</FormGroup>
					</Row>
					<Row className="justify-content-center">
						<FormGroup>
						<Input type="email" name="email" id="exampleEmail" className='py-1' placeholder='Your email' onChange={(e)=>ChangeHandler(e)} />
						</FormGroup>
					</Row>
					<Row className="justify-content-center ">
						<FormGroup>
						<Input type="name" name="mobileNo" id="exampleNumber" className='py-1' placeholder='Your Number' onChange={(e)=>ChangeHandler(e)} />
						</FormGroup>
					</Row>
					<Row className="justify-content-center ">
						<FormGroup>
						<Input type="textarea" name="text" id="exampleText" placeholder='message' className='message-box' onChange={(e)=>ChangeHandler(e)} />
						</FormGroup>
					</Row> 
					<Row className="justify-content-center ">
						<Button color='primary' className='btn btn-md rounded-pill border-2x w-75'>Send </Button>
					</Row>
			
				</Form>
            </div>
          </Col>
          <Col md={6}>
            <div className="map_container">
              <div id="googleMap"></div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact

