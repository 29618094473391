import React from 'react'
import logo from '../assets/images/logo.jpg'
import {Row,Col,Container} from 'reactstrap'
const Logo = () => {
  return (
	<section className="logo-section">
		<Container className='container py-2'>
			<Row className=''>
				<img className='col-md-2 logo' src={logo}></img>
				<div className='col my-auto'>
					<a href='/' className='fs-4 text-decoration-none text-dark'>IRG Goverment Polytechnic,Saharsa</a>
					{/* <p className='fw-lighter'>(Dept. of Science & Technology,Govt of Bihar)</p> */}
				</div>
			</Row>
		</Container>
		</section>
  )
}

export default Logo