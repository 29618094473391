import React, { useState,useEffect } from 'react'
import Cookies from 'js-cookie'
import axios from 'axios';
import {ReactTitle} from 'react-meta-tags';
import { Container,Row,Col, Button,Pagination,PaginationItem,PaginationLink} from 'reactstrap';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPencil, faPhone} from '@fortawesome/free-solid-svg-icons'
import 'react-accessible-accordion/dist/fancy-example.css';
import Sidebar from './Sidebar';

const QueryList = () => {
	const [state,SetState]=useState({
		queryList:[],
		pageSize:10,
		currentPage:0,
		pageCount:0
	})
	useEffect(() => {
		getQuery();
		}, []);
	
  const handleClick=(e,index)=>{
    e.preventDefault();
    SetState({...state,currentPage:index});
  }

	const getQuery =async ()=>{
		
		const header={
		  'x-auth-token': `${Cookies.get('auth_token')}`
		}
		await axios.get(`https://my-college-app.herokuapp.com/api/query/list`,{
			  headers:header})
			  .then(res=>{
          const pagesNo=Math.ceil(res.data.length/state.pageSize);
          SetState({...state,queryList:res.data,pageCount:pagesNo});
			  }).catch(err=>{
				console.log(err);
			});	
		  };
  return (
    <>
    <Sidebar/>
	<section>
  <ReactTitle title="user-query"/>
		<Container className='justify-content-center'>
    <Row className="justify-content-center align-items-center pt-5" >
				<Col md={8}>
          <Pagination aria-label="Page navigation example">
                
            <PaginationItem disabled={state.currentPage <= 0} className={'paginationItemStyle'}>
              <PaginationLink
                onClick={e => handleClick(e, state.currentPage - 1)}
                previous
                href="#"
                className={'paginationLinkStyle'}
              />
            </PaginationItem>

            {[...Array(state.pageCount)].map((page, i) => 
              <PaginationItem active={i === state.currentPage} key={i} className={'paginationItemStyle'}>
                <PaginationLink onClick={e =>handleClick(e, i)} href="#" className={'paginationLinkStyle'}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem disabled={state.currentPage >= state.pageCount - 1} className={'paginationItemStyle'}>
              <PaginationLink
                onClick={e => handleClick(e, state.currentPage + 1)}
                next
                href="#"
                className={'paginationLinkStyle'}
              />
            </PaginationItem>
                
          </Pagination>
		  </Col>
			</Row>
			<Row className="justify-content-center align-items-center " >
						<Col md={8}>
            <h2 className='my-4'>Customer Queries</h2>
            <Accordion allowZeroExpanded>
              {
              state.queryList
              .slice(state.currentPage*state.pageSize,
                              (state.currentPage+1)*state.pageSize)
              .map(function(query,key){
                return (
                  <AccordionItem key={query._id}>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {query.name}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p><FontAwesomeIcon className='px-3'icon={faPhone}/><span>{query.MobileNo}</span></p>
                      <p><FontAwesomeIcon className='px-3'icon={faEnvelope}/><span>{query.email}</span></p>
                      <p><FontAwesomeIcon className='px-3'icon={faPencil}/><span>{query.text}</span></p>
                      <div>
                      <Button className='btn rounded-pill border-2x mx-2 'color='primary' outline>Reply</Button>
                      <Button className='btn rounded-pill border-2x 'color='danger' outline>Delete</Button>
                      </div>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
                )
          })}
          </Accordion>
					  </Col>
			</Row>
			</Container>
	</section>
  </>
  )
}

export default QueryList