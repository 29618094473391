import React from 'react'
import 'react-accessible-accordion/dist/fancy-example.css';
import Notice from './Notice';
import Sidebar from './Sidebar';
import {ReactTitle} from 'react-meta-tags';

const Dashboard = () => {
  return (
  
  <section>
    <ReactTitle title="admin-dashboard"/>
    <Sidebar/>
      <Notice/>
		</section> 
  )
}

export default Dashboard