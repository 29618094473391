import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope,faMapMarker, } from '@fortawesome/free-solid-svg-icons'
import {faLinkedin,faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons'
import { Container, Row,Col } from 'reactstrap'

const Info = () => {
  return (
	<section className="info_section ">
	<Container>
	  <Row>
		<Col className=" mx-auto">
		  <hr></hr>
		</Col>
	  </Row>
	  <div className="info_box ">
		<div className="info_contact">
		  <a href=""className='info-address'>
		  <FontAwesomeIcon className='mx-3' width={40} height={40} icon={faMapMarker}/>
			<span>
			Government Polytechnic, Saharsa
			 At: Industrial Area, Saharsa
			 Bihar-852201
			</span>
		  </a>
		  <a href="">
		  <FontAwesomeIcon  className='mr-1'width={40} height={40}   icon={faPhone}/>
			<span>
			  +91  9934563798
			</span>
		  </a>
		  <a href="">
		  <FontAwesomeIcon className='mr-1' width={40} height={40}   icon={faEnvelope}/>
			<span>
			irggpsaharsa@gmail.com
			</span>
		  </a>
		</div>
		<div className="social_box">
		  <a href="">
		  <FontAwesomeIcon className='social-icon' icon={faFacebook}/>
		  </a>
		  <a href="">
		  <FontAwesomeIcon className='social-icon' icon={faTwitter}/>
		  </a>
		  <a href="">
		  <FontAwesomeIcon className='social-icon' icon={faLinkedin}/>
		  </a>
		</div>
	  </div>
	</Container>
	
  </section>
  )
}

export default Info