import React, { useEffect, useState } from 'react';
import { Collapse, 
	Nav, Navbar, NavbarBrand,
	NavbarToggler,NavItem, NavLink,
  } from 'reactstrap';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSignIn, faUserEdit, faUserLarge } from '@fortawesome/free-solid-svg-icons';
import jwt_decode from 'jwt-decode';
const NavBar = () => {
	const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [isAdmin,SetIsAdmin]=useState(false)
	const toggle =()=>{
		setNavbarCollapsed(!navbarCollapsed);
	}
  useEffect(() => {
    const token=Cookies.get('auth_token');
    if(token){
		  const user=jwt_decode(token);
		  SetIsAdmin(user.user.isAdmin);
    }
  }, [1])
  
	return (
	<Navbar 
    dark
    
    expand="md"
     style={{backgroundColor:'rgb(217, 60, 157)'}}>
    <NavbarBrand href="/" className='nav-title'>IRG GP, Saharsa</NavbarBrand>
    <NavbarToggler onClick={toggle} />
    <Collapse isOpen={navbarCollapsed} navbar className='justify-content-center m-auto'>
      <Nav className="navbar-nav text-sm-center text-md-start " navbar>
      <NavItem className='mx-3' >
      <NavLink  active className="mx-3 text-white fs-5" href="/">
        Home
      </NavLink>
    </NavItem>
    <NavItem className='mx-3'>
      <NavLink  className="mx-3 text-white fs-5" href="/about">
        About
      </NavLink>
    </NavItem>
    <NavItem className='mx-3'>
    <NavLink  className="mx-3 text-white fs-5" href="/courses/">
         Courses
      </NavLink>
    </NavItem >        
    <NavItem className='mx-3'>
    <NavLink  className="mx-3 text-white fs-5" href="https://rzp.io/l/irggpsaharsa-course-enrollment">
         Enrollment
      </NavLink>
    </NavItem>
    <NavItem className='mx-3'>
    <NavLink  className="mx-3 text-white fs-5" href="https://rzp.io/l/pay-now-irggpsahasra">
         Course Payment
       </NavLink>
    </NavItem>
    {Cookies.get('auth_token')?(isAdmin)? 
                <NavItem className='mx-3'>
                    <NavLink className='mx-3 fs-5' href="/dashboard"><FontAwesomeIcon className='user-icon text-white'icon={faUserEdit}/></NavLink>
                </NavItem>:
                <NavItem className='mx-3'>
                  <NavLink className='mx-3 fs-5' href="/profile"><FontAwesomeIcon className='user-icon text-white'icon={faUserLarge}/></NavLink>
               </NavItem> 
            :
              <NavItem className='mx-3'>
              <NavLink className='mx-3 fs-5 text-white' href="/login">Login<FontAwesomeIcon className=' mx-1 text-white'icon={faSignIn}/></NavLink>
            </NavItem>
              }
      </Nav>
    </Collapse>
  </Navbar>
  )
}

export default NavBar


