import React, { Fragment } from 'react'
import AllCourse from './AllCourse'
import Footer from './Footer';
import {ReactTitle} from 'react-meta-tags';
const Course = () => {
  return (
	<Fragment>
    <ReactTitle title="courses"/>
    <AllCourse/>
    <Footer/>
  </Fragment>
  )
}

export default Course