import React from 'react'
import {ReactTitle} from 'react-meta-tags';
import { Container,Row,Col, Button} from 'reactstrap'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
  } from 'react-accessible-accordion';
const Policy = () => {
  return (
	<section className='policy-section'>
  <ReactTitle title="privacy-policy"/>
		<Container className='justify-content-center pt-5'>
			<h2 className='text-center'>Privacy Policy</h2>
			<Row className="justify-content-center align-items-center pt-2" >
				<h2 className="fw-bold">1.</h2>
				<p>
					The entity Tutoring solution Pvt Ltd ("us", "we", or "our") operates the website
					<a target="blank" href="/www.igrgpsaharsa.com ">www.igrgpsaharsa.com </a>(the "Service").
					This page informs you of our policies regarding the collection, use, and disclosure of personal
					data when you use our Service and the choices you have associated with that data on our
					websites, mobile applications or other online or mobile products and services (collectively,
					the “Services”).
					We use your data to provide and improve the Service. By using the Service, you agree to the
					collection and use of information in accordance with this policy. Unless otherwise defined in
					this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms
					and Conditions, accessible from https://tutoring.com
				</p>
						
			</Row>
			<h5 className="fw-bold">Definitions</h5>
			<Row className="justify-content-center align-items-center pt-2" >
				<p>
					<span className='fw-bold'>Service:</span>
					services are the websites www.vidyayan.in operated by Government Polytechnic,
					Saharsa.
				</p>
				<p>
					<span className='fw-bold'>Service:</span>
					services are the websites, mobile application or other online or mobile products
					operated by Government Polytechnic, Saharsa
				</p>
				<p>
					<span className='fw-bold'>Personal Data:</span>
					Personal Data means data about a living individual who can be identified from
					those data (or from those and other information either in our possession or likely to come into
					our possession).
				</p>
				<p>
					<span className='fw-bold'>Usage Data: </span>
					usage Data is data collected automatically either generated by the use of the
					Service or from the Service infrastructure itself (for example, the duration of a page visit)
				</p>
				<p>
					<span className='fw-bold'>Cookies:</span>
					cookies are small pieces of data stored in your device (computer or mobile device)
				</p>
				<p>
					<span className='fw-bold'>Data Controller:</span>
					Data Controller means the natural or legal person who (either alone or
					jointly or in common with other persons) determines the purposes for which and the manner
					in which any personal information are, or are to be, processed. For the purpose of this Privacy
					Policy, we are a Data Controller of your Personal Data.
				</p>
				<p>
					<span className='fw-bold'>Data Processors (or Service Providers):</span>
					data Processor (or Service Provider) means any
					natural or legal person who processes the data on behalf of the Data Controller. We may use
					the services of various Service Providers in order to process your data more effectively.
				</p>
				<p>
					<span className='fw-bold'>Data Subject (or User): </span>
					data Subject is any living individual who is using our Service and is
					he subject of Personal Data.
				</p>
				
						
			</Row>
			<Row className="justify-content-center align-items-center pt-5" >
				<h3 className="fw-bold">Information Collection and Use</h3>
				<p>
				We collect several different types of information to understand how the platform is being
				used to provide and improve our Service to you
				</p>
					
			</Row>
			<h4 className="fw-bold my-3">Types of Data Collected</h4>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Personal Data</h5>
				<p>
				While using our Service, we may ask you to provide us with certain personally identifiable
				information that can be used to contact or identify you ("Personal Data"). Personally
				identifiable information may include, but is not limited to:
				</p>
				<ul className='list-unstyled m-2'>
				<li>Email address</li>
				<li>First name and last name</li>
				<li>Phone Number</li>
				<li>Address, State, Province, ZIP/Postal code, City</li>	
				</ul>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Cookies and usage data</h5>
				<p>
				We may use your Personal Data to contact you with newsletters, marketing or promotional
				materials and other information that may be of interest to you. You may opt out of receiving
				any, or all, of these communications from us by following the unsubscribe link or instructions
				provided in any email we send.
				</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Usage Data</h5>
				<p>
				We may also collect information on how the Service is accessed and used ("Usage Data").
				This Usage Data may include information such as your computer's Internet Protocol address
				(e.g. IP address), browser type, browser version, the pages of our Service that you visit, the
				time and date of your visit, the time spent on those pages, unique device identifiers and other
				diagnostic data
				</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Location Data</h5>
				<p>
				We may use and store information about your location if you give us permission to do so
				(“Location Data”). We use this data to provide features of our Service, to improve and
				customize our Service.
				You can enable or disable location services when you use our Service at any time, through
				your device settings.
				</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Tracking & Cookies Data</h5>
				<p>
				We use cookies and similar tracking technologies to track the activity on our Service and hold
				certain information.
				</p>
				<p>
				Cookies are files with a small amount of data which may include an anonymous unique
				identifier. Cookies are sent to your browser from a website and stored on your device.
				Tracking technologies also used are beacons, tags, and scripts to collect and track information
				and to improve and analyze our Service.
				</p>
				<p>
				You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
				However, if you do not accept cookies, you may not be able to use some portions of our
				Service.
				</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h5 className="fw-bold">Examples of Cookies we use:</h5>
				<p>
					<span className='fw-bold'>Session Cookies:-</span>
					We use Session Cookies to operate our Service.
				</p>
				<p>
					<span className='fw-bold'>Preference Cookies:- </span>
					We use Preference Cookies to remember your preferences and various
					settings.
				</p>
				<p>
					<span className='fw-bold'>Security Cookies:-</span>
					We use Security Cookies for security purposes
				</p>
				
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
				<h4 className="fw-bold">Use of Data:</h4>
				<p> Government Polytechnic, Saharsa uses the collected data for various purposes:-</p>
				<ul className='list-unstyled'>
					<li className='my-1'>To provide and maintain our Service</li>
					<li className='my-1'>To notify you about changes to our Service</li>
					<li className='my-1'>To allow you to participate in interactive features of our Service when you choose to do so</li>
					<li className='my-1'>To gather analysis or valuable information so that we can improve our Service</li>
					<li className='my-1'>To monitor the usage of our Service</li>
					<li className='my-1'>To detect, prevent and address technical issues</li>
					<li className='my-1'>To provide you with news, special offers and general information about other goods, services
					and events which we offer that are similar to those that you have already purchased or
					enquired about unless you have opted not to receive such information</li>
				</ul>

				<p className='fw-bold'>Government Polytechnic, Saharsa may process your Personal Data because:-</p>
				<ul className='list-unstyled'>
					<li className='my-1'>We need to perform a contract with you</li>
					<li className='my-1'>You have given us permission to do so</li>
					<li className='my-1'>The processing is in our legitimate interests and it's not overridden by your rights</li>
					<li className='my-1'>For payment processing purposes</li>
					<li className='my-1'>To comply with the law</li>
					<li className='my-1'>Retention of Data</li>
					
				</ul>
				<p>Government Polytechnic, Saharsa will retain your Personal Data only for as long as is
				necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal
				Data to the extent necessary to comply with our legal obligations (for example, if we are
				required to retain your data to comply with applicable laws), resolve disputes, and enforce
				our legal agreements and policies.</p>
				<p>Government Polytechnic, Saharsa will also retain Usage Data for internal analysis purposes.
				Usage Data is generally retained for a shorter period of time, except when this data is used to
				strengthen the security or to improve the functionality of our Service, or we are legally
				obligated to retain this data for longer time periods.</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
					<h4 className='fw-bold'>Transfer of Data</h4>
					<p>
					Your information, including Personal Data, may be transferred to — and maintained on —
					computers located outside of your state, province, country or other governmental jurisdiction
					where the data protection laws may differ from those from your jurisdiction.
					Your consent to this Privacy Policy followed by your submission of such information
					represents your agreement to that transfer.
					</p>
					<p>
					Government Polytechnic, Saharsa will take all steps reasonably necessary to ensure that your
					datad is treated securely and in accordance with this Privacy Policy and no transfer of your
					Personal Data will take place to an organization or a country unless there are adequate
					controls in place including the security of your data and other personal information.
					</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2" >
					<h4 className='fw-bold'>Disclosure of Data</h4>
					<h5 className='fw-bold my-3'>Business Transaction</h5>
					<p>
					If Government Polytechnic, Saharsa is involved in a merger, acquisition or asset sale, your
					Personal Data may be transferred. We will provide notice before your Personal Data is
					transferred and becomes subject to a different Privacy Policy
					</p>
					<p>
					Government Polytechnic, Saharsa may disclose your Personal Data in the good faith belief
					that such action is necessary to:
					</p>
					<ul className='list-unstyled'>
					<li className='my-1'>To comply with a legal obligation</li>
					<li className='my-1'>To protect and defend the rights or property of Government Polytechnic, Saharsa</li>
					<li className='my-1'>To prevent or investigate possible wrongdoing in connection with the Service</li>
					<li className='my-1'>To protect the personal safety of users of the Service or the public</li>
					<li className='my-1'>To protect against legal liability</li>
				</ul>
				<p>
					The security of your data is important to us, but remember that no method of transmission
					over the Internet, or method of electronic storage is 100% secure. While we strive to use
					commercially acceptable means to protect your Personal Data, we cannot guarantee its
					absolute security.
					Your Data Protection Rights Under General Data Protection Regulation (GDPR).If you wish to be informed what Personal Data we hold about you and if you want it to be
					removed from our systems, please contact us.
					</p>
					<p>
					In certain circumstances, you have the following data protection rights:
					</p>
					<p>
					The right to access, update or to delete the information we have on you. Whenever made
					possible, you can access, update or request deletion of your Personal Data directly within
					your account settings section. If you are unable to perform these actions yourself, please
					contact us to assist you.
					</p>
					<p>
					<span className='fw-bold'>The right of rectification:- </span>
					You have the right to have your information rectified if that
					information is inaccurate or incomplete.
					</p>
					<p>
					<span className='fw-bold'>The right to object:-</span>
					You have the right to object to our processing of your Personal Data.
					</p>
					<p>
					<span className='fw-bold'>The right of restriction:- </span>
					You have the right to request that we restrict the processing of your
					personal information.
					</p>
					<p>
					<span className='fw-bold'>The right to data portability:- </span>
					You have the right to request that we restrict the processing of your
					personal information.
					</p>
					<p>
					<span className='fw-bold'>The right to withdraw consent:-</span>
					You also have the right to withdraw your consent at any time
where Tutoring solution Pvt Ltd relied on your consent to process your personal information.
					</p>
					<p>Please note that we may ask you to verify your identity before responding to such requests.</p>
			</Row>
			<Row className="justify-content-center align-items-center pt-2">
					<h5>Service Providers</h5>
					<p>
					We may employ third party companies and individuals to facilitate our Service ("Service
					Providers"), to provide the Service on our behalf, to perform Service-related services or to
					assist us in analyzing how our Service is used.
					These third parties have access to your Personal Data only to perform these tasks on our
					behalf and are obligated not to disclose or use it for any other purpose
					</p>
					<h5>Analytics</h5>
					<p>
					We may use third-party Service Providers to monitor and analyze the use of our Service.
					Google Analytics. Google Analytics is a web analytics service offered by Google that tracks
					and reports website traffic. Google uses the data collected to track and monitor the use of our
					Service. This data is shared with other Google services. Google may use the collected data to
					contextualize and personalize the ads of its own advertising network. You can opt-out of
					having made your activity on the Service available to Google Analytics by installing the
					Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript
					(ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits
					activity. For more information on the privacy practices of Google, please visit the Google
					Privacy & Terms web page: <a href='https://policies.google.com/privacy?hl=en'>policies.google.com/privacy</a>
					</p>
					<h5>KlipFolio</h5>
					<p>
					Klipfolio is an online service that enables its Customers (“Service") to aggregate
					diverse data from multiple sources and quickly create a new, actionable perspective on their
					business (“Service"). The Service integrates with hundreds of data sources ranging from
					social media platforms to enterprise management services. For more information on the
					privacy practices of Klipfolio, please visit their Privacy Statement here:<a href='https://www.klipfolio.com/legal/privacy-statement'>klipfolio.com/legal/privacy-statement</a>
					</p>
					<h5>Intercom</h5>
					<p>
					We use Intercom to help us know who is using our product or website and to
					personally communicate with them through targeted content, behavior-driven email, in-app,
					and web messages. Intercom helps us meet our data portability requirements for GDPR, we
					can easily export all of our data or granular subsets linked to an individual and permanently
					delete all data linked to an individual user. Intercom also automatically exposes visitor data
					that has not been seen in 9 months, to ensure compliance with GDPR.
					</p>
					<h5>Hotjar</h5>
					<p>
					We use Hotjar in order to better understand our users’ needs and to optimize this
					service and experience. Hotjar is a technology service that helps us better understand our
					users' experience (e.g. how much time they spend on which pages, which links they choose to
					click, what users do and don’t like, etc.) and this enables us to build and maintain our service
					with user feedback. Hotjar uses cookies and other technologies to collect data on our users’
					behavior and their devices (in particular device's IP address (captured and stored only in
					anonymized form), device screen size, device type (unique device identifiers), browser
					information, geographic location (country only), preferred language used to display our
					website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor
					we will ever use this information to identify individual users or to match it with further data
					on an individual user. For further details, please see Hotjar’s privacy policy here:
					<a href='https://www.hotjar.com/legal/policies/privacy'></a>hotjar.com/legal/policies/privacy. You can opt-out to the creation of a user
					profile, Hotjar’s storing of data about your usage of our site and Hotjar’s use of tracking
					cookies on other websites by following this opt-out link.
					</p>
					<h5>Payments</h5>
					<p>
					We may provide paid products and/or services within the Service. In that case, we use
					third-party services for payment processing (e.g. payment processors).
					We will not store or collect your payment card details. That information is provided directly
					to our third-party payment processors whose use of your personal information is governed by
					their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as
					managed by the PCI Security Standards Council, which is a joint effort of brands like Visa,
					Mastercard, American Express and Discover.
					</p>
					<h5>The payment processors we work with are:</h5>
					<p>Razorpay:- Their Privacy Policy can be viewed at <a href='https://razorpay.com/privacy/'></a>razorpay.com/privacy/</p>
					<p>Links to Other Sites</p>
					<p>Our Service may contain links to other sites that are not operated by us. If you click on a third
					party link, you will be directed to that third party's site. We strongly advise you to review the
					Privacy Policy of every site you visit.</p>
					<p>
					We have no control over and assume no responsibility for the content, privacy policies or
					practices of any third party sites or services.
					</p>
			</Row>

			<Row className="justify-content-center align-items-center pt-2">
				<h2>2.</h2>
				<h2>Privacy Policy</h2>
				<p>This Privacy Policy governs the manner in which Government Polytechnic, Saharsacollects,
					uses, maintains and discloses information collected from users (each, a "User") of
					the www.irggpsaharsa.com website ("Site"). This privacy policy applies to the Site and all
					products and services offered by Government Polytechnic, Saharsa.</p>		
			</Row>	
			<Row className="justify-content-center align-items-center py-3">
			<Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading >
                        <AccordionItemButton>
							Personal identification information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>We may collect personal identification information from Users in a variety of ways,
including, but not limited to, when Users visit our site, register on the site, and in connection
with other activities, services, features or resources we make available on our Site. Users may
be asked for, as appropriate, email address, phone number, credit card information. We will
collect personal identification information from Users only if they voluntarily submit such
information to us. Users can always refuse to supply personal identification information,
except that it may prevent them from engaging in certain Site related activities.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Non-personal identification information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>We may collect non-personal identification information about Users whenever they interact
						with our Site. Non-personal identification information may include the browser name, the
						type of computer and technical information about Users' means of connection to our Site,
						such as the operating system and the Internet service providers utilized and other similar
						information.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Web browser cookies
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Our Site may use "cookies" to enhance User experience. User's web browsers place cookies
						on their hard drive for record-keeping purposes and sometimes to track information about
						them. Users may choose to set their web browser to refuse cookies, or to alert you when
						cookies are being sent. If they do so, note that some parts of the Site may not function
						properly.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						How we use collected information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Government Polytechnic, Saharsa may collect and use Users personal information for the
						following purposes:</p>
						<ul>
							<li>
								<h5>-To improve customer service</h5>
								<p>Information you provide helps us respond to your customer service requests and support
									needs more efficiently.</p>
							</li>
							<li>
								<h5>-To personalize user experience</h5>
								<p>We may use information in the aggregate to understand how our Users as a group use the
								services and resources provided on our Site.</p>
							</li>
							<li>
								<h5>-To improve our Site</h5>
								<p>We may use feedback you provide to improve our products and services.</p>
							</li>
							<li>
								<h5>- To process payments</h5>
								<p>We may use the information Users provide about themselves when placing an order only
								to provide service to that order. We do not share this information with outside parties
								except to the extent necessary to provide the service.</p>
							</li>
							<li>
								<h5>- To run a promotion, contest, survey or other Site feature</h5>
								<p>To send Users information they agreed to receive about topics we think will be of
									interest to them.</p>
							</li>
							<li>
								<h5>-To send periodic emails</h5>
								<p>We may use the email address to send User information and updates pertaining to their
								order. It may also be used to respond to their inquiries, questions, and/or other requests.
								If User decides to opt-in to our mailing list, they will receive emails that may include
								company news, updates, related product or service information, etc. If at any time the
								User would like to unsubscribe from receiving future emails, we include detailed
								unsubscribe instructions at the bottom of each email</p>
							</li>
						</ul>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						How we protect your information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>We adopt appropriate data collection, storage and processing practices and security measures
						to protect against unauthorized access, alteration, disclosure or destruction of your personal
						information, username, password, transaction information and data stored on our Site.
						Sensitive and private data exchange between the Site and its Users happens over a SSL
						secured communication channel and is encrypted and protected with digital signatures.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Sharing your personal information
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>We do not sell, trade, or rent Users personal identification information to others. We may
						share generic aggregated demographic information not linked to any personal identification
						information regarding visitors and users with our business partners, trusted affiliates and
						advertisers for the purposes outlined above.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Third party websites
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Users may find advertising or other content on our Site that links to the sites and services of
						our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not
						control the content or links that appear on these sites and are not responsible for the practices
						employed by websites linked to or from our Site. In addition, these sites or services, including
						their content and links, may be constantly changing. These sites and services may have their
						own privacy policies and customer service policies. Browsing and interaction on any other
						website, including websites which have a link to our Site, is subject to that website's own
						terms and policies.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Advertising
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Ads appearing on our site may be delivered to Users by advertising partners, who may set
						cookies. These cookies allow the ad server to recognize your computer each time they send
						you an online advertisement to compile non personal identification information about you or
						others who use your computer. This information allows ad networks to, among other things,
						deliver targeted advertisements that they believe will be of most interest to you. This privacy
						policy does not cover the use of cookies by any advertisers.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Google Adsense
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Some of the ads may be served by Google. Google's use of the DART cookie enables it to
						serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses
						"non personally identifiable information" and does NOT track personal information about
						you, such as your name, email address, physical address, etc. You may opt out of the use of
						the DART cookie by visiting the Google ad and content network privacy policy at <a href=" http://www.google.com/privacy_ads.html"> http://www.google.com/privacy_ads.html</a></p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Compliance with children's online privacy protection act
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Protecting the privacy of the very young is especially important. For that reason, we never
						collect or maintain information at our Site from those we actually know are under 13, and no
						part of our website is structured to attract anyone under 13.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Changes to this privacy policy
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Vidyayan Eduventure Pvt Ltd has the discretion to update this privacy policy at any time.
						When we do, we will post a notification on the main page of our Site. We encourage Users to
						frequently check this page for any changes to stay informed about how we are helping to
						protect the personal information we collect. You acknowledge and agree that it is your
						responsibility to review this privacy policy periodically and become aware of modifications.</p>
                      
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Your acceptance of these terms
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>By using this Site, you signify your acceptance of this policy and terms of service. If you do
						not agree to this policy, please do not use our Site. Your continued use of the Site following
						the posting of changes to this policy will be deemed your acceptance of those changes.</p>
											
                    </AccordionItemPanel>
                  </AccordionItem>
				  <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
						Contacting us
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
					<p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings
						with this site, please contact us at:</p>
				<p>Government Polytechnic, Saharsa</p>
				<p>www.irggpsaharsa.com</p>
				<p>irggpsaharsa@gmail.com</p>
											
                    </AccordionItemPanel>
                  </AccordionItem>
          </Accordion>
			</Row>
		</Container>
	</section>
  )
}

export default Policy
