import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { Container,Row,Col, ListGroup,ListGroupItem,Pagination,PaginationItem,PaginationLink,Button} from 'reactstrap';
import Sidebar from './Sidebar';
import {ReactTitle} from 'react-meta-tags';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode'
const NoticeList = () => {
	const [state,SetState]=useState({
		noticeList:[],
		pageSize:10,
		currentPage:0,
		pageCount:0
	})
	useEffect(() => {
		getNotice();
	}, []);

	const handleDelete=async(e,notice)=>{
		e.preventDefault();
			const header={
				'x-auth-token': `${Cookies.get('auth_token')}`
			}
		await axios.delete(`https://my-college-app.herokuapp.com/api/notice/${notice._id}`,{headers:header})
		  .then(res=>{
			window.alert("notice deleted successfully")
			  getNotice();
		  }).catch(err=>{
			});
	  }

	const handleClick=(e,index)=>{
		e.preventDefault();
		SetState({...state,currentPage:index});
	}
	const getNotice =async ()=>{
		await axios.get(`https://my-college-app.herokuapp.com/api/notice/list`)
			  .then(res=>{
				const pagesNo=Math.ceil(res.data.length/state.pageSize);
			  SetState({...state,noticeList:res.data,pageCount:pagesNo});
			  }).catch(err=>{
				console.log(err);
			});	
	};
  return (
	<>
	<Sidebar/>
	<section className='noticeList-section'>
	<ReactTitle title="latest-notice"/>
		<Container className='justify-content-center'>
			<Row className="justify-content-center align-items-center pt-5" >
				<Col md={8}>
			<Pagination  aria-label="Page navigation example">
            
            <PaginationItem disabled={state.currentPage <= 0} className={'paginationItemStyle'} >
              
              <PaginationLink
                onClick={e => handleClick(e, state.currentPage - 1)}
                previous
                href="#"
				className={'paginationLinkStyle'}
              />
              
            </PaginationItem>

            {[...Array(state.pageCount)].map((page, i) => 
              <PaginationItem active={i === state.currentPage} key={i} className={'paginationItemStyle'}>
                <PaginationLink onClick={e =>handleClick(e, i)} href="#" className={'paginationLinkStyle'}>
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem disabled={state.currentPage >= state.pageCount - 1} className={'paginationItemStyle'}>
              
              <PaginationLink
                onClick={e => handleClick(e, state.currentPage + 1)}
                next
                href="#"
				className={'paginationLinkStyle'}
              />
              
            </PaginationItem>
            
          </Pagination>
		  </Col>
			</Row>
			<Row className="justify-content-center align-items-center " >
				<Col md={8}>
					<h2 className='my-4'>Notice</h2>
					<ListGroup>
					{
						state.noticeList.slice(state.currentPage*state.pageSize,
												(state.currentPage+1)*state.pageSize)
						.map(function(notice,key){
						return (
							<ListGroupItem key={key} className="d-flex justify-content-between">
						<a
							href={notice.img?notice.img:'/'}
							tag="a"
							target="blank"
							className='text-dark'
						>
						{notice.title}
						</a>
						<p>{notice.date.substring(0,10)}</p>
						<Button className='btn rounded-pill' color='danger' outline  onClick={(e)=>handleDelete(e,notice)}>
							Delete 
							</Button>
						
						</ListGroupItem>
						)
						})}
					</ListGroup>
				</Col>
			</Row>
			</Container>
	</section>
	</>
  )
}

export default NoticeList