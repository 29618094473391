import React,{useState,useEffect, Fragment} from 'react'
import {
	Row,
	Col,
	Button,
	Container,
	Carousel,
	CarouselItem,
	CarouselControl,
	CarouselIndicators,
	CarouselCaption,
  } from 'reactstrap';
  import axios from 'axios';
import Slider1 from '../assets/images/slider-01.jpg';
import Slider2 from '../assets/images/slider-02.jpg';
import Slider3 from '../assets/images/slider-03.jpg';
import Slider7 from '../assets/images/slider-07.jpg';
import Slider8 from '../assets/images/slider-08.jpg';
import Slider9 from '../assets/images/slider-09.jpg';
import Slider10 from '../assets/images/slider-10.jpg';
import Slider11 from '../assets/images/slider-11.jpg';
const Slider = () => {

	const [state,setState]=useState({
		activeIndex:0,
		animating:false,
		noticeList:[]
	})
	useEffect(() => {
		getNotice();
	}, []);
	const items = [
		{
			src: Slider7,
			altText: 'Slide 4',
			caption: 'Land Survey'
		  },
		{
		  src: Slider1,
		  altText: 'Slide 1',
		  caption: 'Electronics Building'
		},
		{
		  src: Slider2,
		  altText: 'Slide 2',
		  caption: 'Work Shop'
		},
		{
		  src: Slider3,
		  altText: 'Slide 3',
		  caption: 'Main Building'
		},
		
		  {
			src: Slider8,
			altText: 'Slide 8',
			caption: 'CS Lab'
		  },
		  {
			src: Slider9,
			altText: 'Slide 9',
			caption: 'WorkShop'
		  },
		  {
			  src: Slider10,
			  altText: 'Slide 10',
			  caption: 'Site Visit'
			},
			{
				src: Slider11,
				altText: 'Slide 11',
				caption: 'CS Lab'
			  }
	  ];

	  const onExiting=()=> {
		setState({...state,animating:true});
	  }
	
	  const onExited=()=> {
		setState({...state,animating:false});
	  }
	
	  const next=()=> {
		if (state.animating) return;
		const nextIndex = state.activeIndex === items.length - 1 ? 0 : state.activeIndex + 1;
		setState({ ...state,activeIndex: nextIndex });
	  }
	
	  const previous=()=> {
		if (state.animating) return;
		const nextIndex = state.activeIndex === 0 ? items.length - 1 : state.activeIndex - 1;
		setState({...state, activeIndex: nextIndex });
	  }
	
	  const goToIndex=(newIndex)=> {
		if (state.animating) return;
		setState({...state, activeIndex: newIndex });
	  }

	const getNotice =async ()=>{
		await axios.get(`https://my-college-app.herokuapp.com/api/notice/list`,)
			  .then(res=>{
			  setState({...state,noticeList:res.data});
			  }).catch(err=>{
				console.log(err);
			});	
	};

  return (
	<section className="slider-section">
		<Container className='container slider-container'>
			<Row className='justify-content-center'>
				<Col  lg={8}  className="text-col">
				<Carousel
					activeIndex={state.activeIndex}
					next={next}
					previous={previous}
				>
					<CarouselIndicators items={items} activeIndex={state.activeIndex} onClickHandler={goToIndex} />
					{items.map((item) => {
								return (
								<CarouselItem
									className='slider-item'
									onExiting={onExiting}
									onExited={onExited}
									key={item.src}
									 style={{backgroundImage:`${item.src}`}}
								>
									<img className="carousel-img" src={item.src} alt={item.altText} />
									<CarouselCaption className='slider-caption'  captionText={item.caption} />
								</CarouselItem>
								);
							})}
					<CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
					<CarouselControl direction="next" directionText="Next" onClickHandler={next} />
				</Carousel>				
				</Col>
				<Col  lg={4} >
				<div className="notice-section">
					<span className="notice-heading">Latest Notice</span>
						<div class="microsoft areas">
							<div class="marquee">
							{ state.noticeList.map(function(notice){
								return (
									<Fragment>
									<a className="notice-title text-decoration-none border-bottom-2" target="blank" href={notice.img?notice.img:'/'}>
									<p className="px-3 py-1 mx-2 my-auto notice-date">{notice.date.substring(0,10)}</p>
									{notice.title}</a>
									<hr className='my-3'></hr>
									</Fragment>
								)
								})}	
							</div>
						</div>
				</div>
				</Col>
			</Row>
		</Container>
		</section>
  )
}

export default Slider