import { Navigate } from "react-router";
import Cookies from 'js-cookie';
const PrivateRoute = ({children }) => {
	if (!Cookies.get('auth_token')) {
	  return <Navigate to="/" replace />;
	}

	return children;
  };

export default PrivateRoute;