import NavBar from "./components/NavBar";
import './assets/css/style.scss'
import {Routes ,Route} from 'react-router-dom';
import Home from "./components/Home";
import About from "./components/About";
import Course from "./components/Course";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Register from "./components/Register";
import AdminRoute from './Routing/AdminRoute';
import PrivateRoute from './Routing/PrivateRoute';
import Profile from "./components/Profile";
import Logo from "./components/Logo";
import QueryList from "./components/QueryList";
import NoticeList from "./components/NoticeList";
import Policy from "./components/Policy";
import Conditions from "./components/Conditions";
import UserList from "./components/UserList";

function App() {
  return (
    <div className="App">
      <Logo/>
      <NavBar/>
      <Routes>
        <Route  path="/" element={<Home/>}/>
        <Route path="/courses" element={<Course/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/policy" element={<Policy/>}/> 
        <Route path="/terms&conditions" element={<Conditions/>}/>
        <Route path="/profile" element={<PrivateRoute>
                                                  <Profile/>
                                                </PrivateRoute>}/>
        <Route exact path="/dashboard" element={<AdminRoute>
                                                  <Dashboard/>
                                                </AdminRoute>}/>
        <Route exact path="/notices" element={<AdminRoute>
                                              <NoticeList/>
                                            </AdminRoute>}/> 
        <Route exact path="/queries" element={<AdminRoute>
                                                  <QueryList/>
                                                </AdminRoute>}/>
        <Route exact path="/users" element={<AdminRoute>
                                                  <UserList/>
                                                </AdminRoute>}/>

        </Routes>
    </div>
  );
}

export default App;
