import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Slider1 from '../assets/images/slider-01.jpg';
import Slider2 from '../assets/images/slider-02.jpg';
import Slider3 from '../assets/images/slider-03.jpg';
import Slider5 from '../assets/images/slider-05.jpg';
import Slider6 from '../assets/images/slider-06.jpg';
import Slider8 from '../assets/images/slider-08.jpg';
import Slider9 from '../assets/images/slider-09.jpg';
import Slider10 from '../assets/images/slider-10.jpg';
import Slider11 from '../assets/images/slider-11.jpg';
import { Container,Row,Col } from "reactstrap";

const Gallery=()=> {
	const [Show, SetShow]=useState(3);
    const settings = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "60px",
		slidesToShow: Show,
		speed: 500
	  };
	  const items = [
		{
		  src: Slider1,
		  altText: 'Slide 1',
		  caption: 'Slide 1'
		},
		{
		  src: Slider2,
		  altText: 'Slide 2',
		  caption: 'Slide 2'
		},
		{
		  src: Slider3,
		  altText: 'Slide 3',
		  caption: 'Slide 3'
		},
		  {
			src: Slider5,
			altText: 'Slide 5',
			caption: 'Slide 5'
		  },
		  {
			src: Slider6,
			altText: 'Slide 6',
			caption: 'Slide 6'
		  },
		  {
			src: Slider8,
			altText: 'Slide 8',
			caption: 'Slide 8'
		  },
		  {
			  src: Slider9,
			  altText: 'Slide 9',
			  caption: 'Slide 9'
			},
			{
			  src: Slider10,
			  altText: 'Slide 10',
			  caption: 'Slide 10'
			},
			{
			  src: Slider11,
			  altText: 'Slide 11',
			  caption: 'Slide 11'
			}
	  ];
	  useEffect(() => {
		if(window.innerWidth<576){
			SetShow(1);
		}else{
			SetShow(3);
		}
	  }, [true])
	  
    return (

		<section className="gallery-section">
    <Container className='justify-content-center'>
				<div>
					<h2>Latest Images</h2>
				</div>
			<Row className="justify-content-center align-items-center pt-2" >
						<Col className=''>
						<Slider {...settings}>
							{items.map((item,key) => {
								return (
									<div key={key}>
										<img className="gallery-img" src={item.src}/>
									</div>
								);
							})}
       					</Slider>
					  </Col>
			</Row>
			</Container>
		</section> 
      
    );
}

export default Gallery