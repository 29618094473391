import React,{useState} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';
import { Container,Row,Col, Button,Form,FormGroup,Label,Input} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const Notice = () => {
	const [state,setState]=useState({
		title:'',
		date:'',
		img:'',
	  });
	const ChangeHandler =(e)=> {
		const value=e.target.value;
		console.log(value)
		setState({...state, [e.target.name]:value});
	  }
	
	  const setFileToBase=(file)=>{
		const reader= new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend=()=>{
		  setState({...state,img:reader.result});
		}
	  }
	
	
	  const fileHandler=(e)=>{
		const file=e.target.files[0];
		  setFileToBase(file);
	  }
	  const SubmitHandler =async (e)=>{
		e.preventDefault();
		const formdata=new FormData();
		formdata.append('title',state.title);
		formdata.append('img',state.img);
		formdata.append('date',state.date);
		await axios.post(`https://my-college-app.herokuapp.com/api/notice/`,formdata,{
		  headers:{
			'Content-Type':'mutltipart/form-data',
			'x-auth-token': `${Cookies.get('auth_token')}`
		  }
		})
		.then(res=>{
			window.alert("added successfully");
		}).catch(err=>{
		  console.log(err)
	  
		});
	  }
	  const history=useNavigate();
	  const logout=()=>{
		Cookies.remove('auth_token');
		history('/')
	  }
  return (
	<section>
		<Container className='justify-content-center'>
			<Row className="justify-content-end  pt-5 " >
				<Button className='btn-outline-danger w-25 rounded-pill border-2x ' outline color='danger' onClick={()=>{logout()}}>Logout</Button>
			</Row>
			<Row className="justify-content-center align-items-center pt-5 " >
				<Col md={8}>
					<Form className=' bg-gradient  rounded-3 p-4 ' onSubmit={(e)=>SubmitHandler(e)}>
					<h2>Add Notice</h2>
					<FormGroup controlId="email" >
						<Label>Title</Label>
						<Input  name="title" type="name" 
										placeholder="title" 
									onChange={(e)=>ChangeHandler(e)}/>
					</FormGroup>
					<FormGroup>
					<Label>Date</Label>
						<Input type="date" name="date" 
								id="exampleNumber" className='py-1'
						 		onChange={(e)=>ChangeHandler(e)} />
					</FormGroup>

					<FormGroup controlId="password">
						<Label>File</Label>
						<Input name="file"  type="file"
						onChange={(e)=>fileHandler(e)}/>
					</FormGroup>
					

					<Button className="btn btn-dark w-100 mt-3 fs-5 w-100"  variant="hidden" type="submit">Submit</Button>
					
					</Form>		
				</Col>
			</Row>
			</Container>
	</section>
  )
}

export default Notice