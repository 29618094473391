import React,{useState,useEffect} from 'react'
import { Link ,useNavigate} from 'react-router-dom';
import {Container, Row,Col,Card,CardBody,CardImg,CardTitle,CardSubtitle,CardText,Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import {ReactTitle} from 'react-meta-tags';

const Profile = () => {
	const [state,setState]=useState({
		username:'user',
		email:'user@gmail.com',
		mobileNo:''
	  });
	  const history=useNavigate();
	  const logout=()=>{
		Cookies.remove('auth_token');
		history('/')
	  }
	  useEffect(() => {
		getUserDetail();
	}, [])
	  const getUserDetail =async ()=>{
		const token=Cookies.get('auth_token');
		if(token){
			const user=jwt_decode(token);
			var userId=(user.user.id);
		}
		// console.log(userId)
		const data={
			'userId':`${userId}`
		}
		const header={
			'x-auth-token': `${Cookies.get('auth_token')}`
		}
		await axios.post(`https://my-college-app.herokuapp.com/api/user/`,data,{
					headers:header})
					.then(res=>{
					setState(res.data);
					}).catch(err=>{
						console.log(err);
				});
				
	}
  return (
	<section className=''>
		<ReactTitle title="user-profile"/>
		<Container className='justify-content-center pt-5'>
		<Row className="justify-content-between profile-header">
				<Col>
					<h2 className='profile-heading fs-2'>
						
						<span><Link to="/"><FontAwesomeIcon className='pr-3 text-black-50' icon={faArrowLeft}/></Link></span>
						Your Profile
					</h2>
				</Col>
				<Col>
					<Button className='btn-outline-danger w-50 rounded-pill border-2x ' outline color='danger' onClick={()=>{logout()}}>Logout</Button>
				</Col>
			</Row>
			<Row className="justify-content-center my-4 mx-md-4 profile-row">
				<Col className=" my-sm-3 px-card">
				<ul className="list-unstyled d-flex flex-wrap">
					<li className='d-flex m-5'>
						<h5 className='profile-title'>Username:</h5>
						<p className='profile-data'>{state.username}</p>
					</li>
					<li className='d-flex m-5'>
						<h5  className='profile-title'>Email:</h5>
						<p className='profile-data'>{state.email}</p>
					</li>
					<li className='d-flex m-5'>
						<h5 className='profile-title'>MobileNo:</h5>
						<p className='profile-data'>{state.mobileNo}</p>
					</li>
				</ul>
				</Col>
			</Row>
			
		</Container>
	</section>
  )
}

export default Profile