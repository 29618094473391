import React from 'react'
import { Container,Row,Col } from 'reactstrap'
 const Mission = () => {
  return (
	<section>
		<Container className='justify-content-center'>
			<Row  className="justify-content-center px-sm-3">
				<Col xl={7} className="my-2">
				<div class="heading_container heading_center">
					<h2>
					OUR <span>VISION</span>
					</h2>
				</div>
					 <h5 className='fs-5 mt-4 text-secondary text-center lh-2'>To contribute to India and the World through excellence 
					 in scientific and technical education and research; to 
					 serve as a valuable resource for industry and society; and to remain a source of pride for all Indians. </h5>
				</Col>
			</Row>
			<Row  className="justify-content-center py-5 px-sm-3">
				<Col xl={10} className="my-1 ">
				<div class="heading_container heading_center">
					<h2>
					OUR <span>MISSION</span>
					</h2>
				</div>
					 <ul className='pt-4 list-unstyled'>
						<li className='fs-5 m-3 text-secondary '>To undertake collaborative projects which offer opportunities for long term interaction with academia and industry. </li>
						<li className='fs-5 m-3 text-secondary '>To develop human potential to its fullest extent so that intellectually capable and imaginatively gifted leaders can emerge in a range of professions. </li>
						<li className='fs-5 m-3 text-secondary '>To generate new knowledge by engaging in cutting-edge research and to promote academic growth by offering state-of-the-art undergraduate, postgraduate and doctoral programmers. </li>
						<li className='fs-5 m-3 text-secondary '>To identify, based on an informed perception of Indian, regional and global needs, areas of specialization upon which the Institute can concentrate.</li>
					 </ul>
				</Col>
			</Row>
		</Container>
	</section>
  )
}

export default Mission