import React, { Fragment } from 'react'
import Intro from './Intro'
import AllCourse from './AllCourse'
import Footer from './Footer'
import Slider from './Slider'
import Gallery from './Gallery';
import {ReactTitle} from 'react-meta-tags';
const Home = () => {
  return (
	<Fragment>
		<ReactTitle title="IRG GP Saharsa"/>
		<Slider/>
		<Intro/>
		<AllCourse/>
		<Gallery/>
		<Footer>
      </Footer>
	</Fragment>
  )
}

export default Home