import React, { Fragment } from 'react'
import Footer from './Footer'
import Intro from './Intro'
import Mission from './Mission'
import {ReactTitle} from 'react-meta-tags';
const About = () => {
	const info='The college in coordination with department is strive to provide best education in the arena of globalized world to achieve the objective of Industry 4.0, this led to importance of computer-based education mandatory in inter discipline learning, so this institute is having best infrastructure and faculty member is to provide best education for the common people of Bihar. The computer laboratories are being modernized and updated to cater the current need of the industry at Government Polytechnic, Saharsa.	In addition to the existing 3-year Diploma Engineering course in different discipline such as Civil Engineering, Mechanical Engineering, Electrical Engineering, Computer Science Engineering and Electronics Engineering. The institution is also providing certification courses as per the current need under the title of non-formal short courses in Computer education under the guidance of state board in addition to technical education under the Department of science and technology, Patna. '
	const href="https://www.gpshc.ac.in/"
	const target="blank"
	return (
	<Fragment>
		<ReactTitle title="about"/>
		<Intro info={info} href={href} target={target}/>
		<Mission/>
		<Footer/>
	</Fragment>
  )
}

export default About