import React from 'react'
import {
	CDBSidebar,
	CDBSidebarContent,
	CDBSidebarFooter,
	CDBSidebarHeader,
	CDBSidebarMenu,
	CDBSidebarMenuItem,
  } from 'cdbreact';
  import { NavLink } from 'react-router-dom';
const Sidebar = () => {
  return (
	<div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial',position:'absolute' }}>
      <CDBSidebar textColor="#fff" backgroundColor="rgb(217, 60, 157)">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Home
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/dashboard" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/queries" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="question">Query</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/notices" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="info">Notice</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/users" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">User</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

         
      </CDBSidebar>
    </div>
  )
}

export default Sidebar