import React, { Fragment, useState } from 'react';
import {Container, Form, Button, Input ,FormGroup,Label ,Row,Col} from 'reactstrap';
import {Link,Navigate,useNavigate} from 'react-router-dom';
import axios from 'axios';
import {ReactTitle} from 'react-meta-tags';

function Register () {

  const [state,setState]=useState({
    username:'',
	  email:'',
    mobileNo:'',
    password:'',
    confirm_password:''
  })
  const history=useNavigate();

  function ChangeHandler(e){
    const value=e.target.value;
    setState({...state, [e.target.name]:value});
  }
  const SubmitHandler =async (e)=>{
    e.preventDefault();
    const data={
      "username":`${state.username}`,
      "email":`${state.email}`,
      "mobileNo":`${state.mobileNo}`,
      "password":`${state.password}`,
      "confirm_password":`${state.confirm_password}`
    }
    await axios.post(`https://my-college-app.herokuapp.com/api/auth/register/`,data)
    .then(res=>()=>{
      history('/login');
      window.alert("register successfully");
    }).catch(err=>{
      console.log(err);
    });
  }

  return (
    <section className="auth-section ">
      <ReactTitle title="register"/>
		<Container className='pt-5'>
			<Row className='justify-content-center  pt-5'>
				<Col md={7} lg={6} xl={5} >
        <Form className='bg-gradient text-white rounded-3 p-5' onSubmit={(e)=>SubmitHandler(e)}>
            <h2>Register</h2>
          <FormGroup controlId="username" className="form-group">
            <Label>Username</Label>
            <Input name="username" type="name" 
              placeholder="username" 
              className="form-control" onChange={(e)=>ChangeHandler(e)}/>
          </FormGroup>
          <FormGroup controlId="email" className="form-group">
            <Label>Email</Label>
            <Input name="email" type="email" 
              placeholder="Example@gmail.com" 
              className="form-control" onChange={(e)=>ChangeHandler(e)}/>
          </FormGroup>
          <FormGroup controlId="username" className="form-group">
            <Label>Mobile No</Label>
            <Input name="mobileNo" type="name" 
              placeholder="Mobile No" 
              className="form-control" onChange={(e)=>ChangeHandler(e)}/>
          </FormGroup>
          <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input type="password" name="password" id="examplePassword" placeholder="Password" onChange={(e)=>ChangeHandler(e)} />
          </FormGroup>
          <FormGroup>
              <Label for="examplePassword">Confirm Password</Label>
              <Input type="password" name="confirm_password" id="examplePassword" placeholder="Confirm Password" onChange={(e)=>ChangeHandler(e)} />
          </FormGroup>
              <FormGroup>
                <Button className="btn btn-dark w-100" variant="hidden" type="submit">Submit</Button>
              </FormGroup>
			  <p className="forgot-password text-right mt-2">Already have a account
                                <span><Link to="/login" className='mx-2 text-dark fs-6'>login</Link></span>
                                </p>
        </Form>
				</Col>
			</Row>
		</Container>

		</section> 
  )
}

export default Register



