import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import { Container,Row,Col,Card,CardBody,CardTitle,CardSubtitle,CardImg,Button ,Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap';
import Course01 from '../assets/images/Course01.jpg';
import Course02 from '../assets/images/Course02.jpg';
import Course03 from '../assets/images/Course03.jpg';
import Course04 from '../assets/images/Course04.jpg';
import Course05 from '../assets/images/Course05.jpg';
import Course06 from '../assets/images/Course06.jpg';
import Course07 from '../assets/images/Course07.jpg';
import Course08 from '../assets/images/Course08.jpg';
import Course09 from '../assets/images/Course09.jpg';
const AllCourse = () => {
	const items = [
		{
		  src: Course01,
		  altText: 'Course 1',
		  caption: 'Land surveying is the technique, profession, and science of accurately determining the terrestrial or three-dimensional position of points and the distances and angles between them, commonly practiced by licensed surveyors, and members of various building professions. ',
		  title:'Land Surveyor(Amanat)',
		  courseDuration:'12 months',
		  eligibility:'Matric'
		},
		{
		  src: Course02,
		  altText: 'Course 2',
		  caption: 'Advance Diploma in Computer Applications acronym is ADCA is a course for a person who is great at mathematics and thinking about computer technology. This course seeks to offer an advanced understanding of computer applications. There is a huge demand for knowledgeable people for handling Computer Applications. ',
		  title:'Advance Diploma In Computer Application(ADCA) ',
		  courseDuration:'12 months',
		  eligibility:'Matric',
		},
		{
		  src: Course03,
		  altText: 'Course 3',
		  caption: 'Modular programming is defined as a software design technique that focuses on separating the program functionality into independent, interchangeable methods/modules. Each of them contains everything needed to execute only one aspect of functionality. ',
		  title:'Modular Course in Computer Programming(MCCP)',
		  courseDuration:'12 months',
		  eligibility:'Intermediate'
		},
		{
			src: Course04,
			altText: 'Course 4',
			caption: 'The Computer Teacher Training (CTTC) programme is aimed at those who want to make their career in Teaching and in the Training sector. The CTTC programme prepares the learners for a specialized career in Teaching and Training. The contents are designed such that not only will the learner gain computer knowledge but also learn how to transfer this knowledge effectively to his students',
			title:'Certificate Course In Computer Teacher Training(CCCTT) ',
		  	courseDuration:'12 months',
		  	eligibility:'Intermediate',
		  },
		  {
			src: Course05,
			altText: 'Course 5',
			caption: 'Data entry operator courses help the students acquire all the necessary technical skills required for the data entry through MS Office and other related softwares. Data entry operator courses are available online as well as offline. ',
			title:'Data Entry Operator(DEO)',
		  	courseDuration:'6 months',
		  	eligibility:'Matric'
		  },
		  {
			src: Course06,
			altText: 'Course 6',
			caption: 'A CNC Operator is a professional who sets up and maintains equipment in a process. They ensure systems run smoothly with little input from anyone else on site. CNC Operators must have an eye for detail and strong working knowledge of their machines capabilities. ',
			title:'CNC Machine Operator ',
		  	courseDuration:'6 months',
		  	eligibility:'Intermediate'
		  },
		  {
			src: Course07,
			altText: 'Course 7',
			caption: 'The Course is designed  from easily understandable resources and has covered testing of Internal Parts, Fault Finding, Replacing the entire wiring of below commonly used home appliances in every household. ',
			title:'Repairing Of Electrical Electronics Appliances',
		  	courseDuration:'6 months',
		  	eligibility:'Matric'
		  },
		  {
			src: Course08,
			altText: 'Course 8',
			caption: 'A lathe operator studies blueprints and tooling instructions to determine the correct procedure for machining the component in such a manner that facilitates maximum eﬃciency in tool usage and time. The operator also performs machine maintenance and checks tools for signs of wear.',
			title:'Lathe Machine Operator ',
		  	courseDuration:'6 months',
		  	eligibility:'Matric'
		  },
		  {
			src: Course09,
			altText: 'Course 9',
			caption: 'This short-term course can be completed in both 3 months or 6 months where the students learn about how to create and maintain e-Commerce sites, blogs and websites. Topics like multimedia and web scripting & graphics are the dominant topics in this course. Technical aspects such as Hosting & Servers, CMSs etc are taught in this short-term course ',
			title:' Web Development And Designing ',
		  	courseDuration:'6 months',
		  	eligibility:'Matric'
		  }
	  ];
	  const [isOpen,setIsOpen]=useState(false);
	  const [modelItem,setModelItem]=useState({});
	  const toggle=()=>{
		setIsOpen(!isOpen);
	  }
	  const ClickHandler=(e,course)=>{
		setModelItem(course)
		toggle();
	  }
  return (
	<section className="course_section layout_padding2">
    <Container>
      <div className="heading_container heading_center">
        <h2>
          Our <span>Courses</span>
        </h2>
      </div>
    </Container>
      <Container className="container">
        <Row className="justify-content-center">
			{
				items.map((course,key) => {
					return (
						<Col md={6}  lg={4}  key={key}>
							<Card className='card' >
								<CardImg
								className='card-img'
									alt="Card image cap"
									src={course.src}
									top
									width="100%"
									/>
							<CardBody className='card-body'>
								<CardTitle tag="h5" className='mb-4 all-course-title'>
									{course.title}
								</CardTitle>
								<CardSubtitle className="mb-2 text-secondary" tag="p" >
									Eligibility:<span className="px-3 text-dark">{course.eligibility}</span>
								</CardSubtitle>
								<CardSubtitle className="mb-2 text-secondary" tag="p" >
									Duration:<span className='px-3 text-dark'>{course.courseDuration}</span>
								</CardSubtitle>
								<Button className="button all-button border-2x rounded-pill mx-4 w-75" color="primary" onClick={(e)=>{ClickHandler(e,course)}}>
									View Details
								</Button>
							</CardBody>
						</Card>
							
					  </Col>
					);
				})
			}
        
       
      </Row>
    </Container>
	<Modal centered isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>{modelItem.title}</ModalHeader>
          <ModalBody>
            {modelItem.caption}
			<p className="my-2 text-secondary" tag="p" >
				Eligibility:<spanc className="px-3 text-dark">{modelItem.eligibility}</spanc>
			</p>
			<p className="mb-2 text-secondary" tag="p" >
				Duration:<span className='px-3 text-dark'>{modelItem.courseDuration}</span>
			</p>
          </ModalBody>
          <ModalFooter>
            <a className='btn btn-dark rounded-pill px-lg-5' color="dark"  href="https://rzp.io/l/irggpsaharsa-course-enrollment" >Enroll</a>{' '}
            <Button className='rounded-pill px-lg-5' color="dark" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
  </section>
  )
}

export default AllCourse