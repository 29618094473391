import { Navigate } from "react-router";
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

const AdminRoute = ({children }) => {
	const token=Cookies.get('auth_token');
	if (token) {
		const user=jwt_decode(token);
		const isAdmin=(user.user.isAdmin);
		if(isAdmin){
			return children;
		}else{
			return <Navigate to="/" replace />;
		}
	}else{
		return <Navigate to="/" replace />;
	}
	
  };

export default AdminRoute;