import React from 'react'
import { Col,Container } from 'reactstrap';
import Info from './Info';
import Contact from './Contact';
const Footer = () => {
	
	
  return (
	<div className="bottom_bg">
    <Contact/>
    <Info/>
	<footer className="footer_section">
    <Container className='footer_box'>
      <Col sm={4}>
      <p>
          &copy; <span id="displayYear"></span> All Rights Reserved By
          <a href="https://techiiefy.com/"> IRG GP Saharsa</a>
        </p>
      </Col>
      <Col sm={3}>
        <p>
          <a href='/terms&conditions'>Terms & Conditions</a>
          
        </p>
      </Col>
      <Col sm={2}>
        <p>
          <a href='/policy'>Privacy & Policy</a>
          
        </p>
      </Col>
      <Col sm={3}>
        <p>
          Design & Developed By Techiiefy.com
          
        </p>
      </Col>
        
       
    </Container>
  </footer>
  </div>
  )
}

export default Footer