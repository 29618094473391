import React, { useState ,useEffect} from 'react';
import {Container, Form, Button,Row,Col,FormGroup,Label,Input} from 'reactstrap';
import {Link, Navigate,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import {ReactTitle} from 'react-meta-tags';

const Login = () => {
  const [state,setState]=useState({
    username:"",
    password:"",
  });
  const history=useNavigate();

  const ChangeHandler =(e)=> {
    const value=e.target.value;
    setState({...state, [e.target.name]:value});
}
const SubmitHandler =async (e)=>{
  e.preventDefault();
  const data={
    "email":`${state.email}`,
    "password":`${state.password}`
  }
  await axios.post(`https://my-college-app.herokuapp.com/api/auth/login/`,data)
  .then(res=>{
    Cookies.set('auth_token',res.data.token);
    const token=Cookies.get('auth_token');
		const user=jwt_decode(token);
		const isAdmin=(user.user.isAdmin);
    if(isAdmin==true){
      history("/dashboard");
      window.alert("login successfull");
    }else{
      history("/");
    }
  }).catch(err=>{
    console.log(err);
  });
}
  return (
    <section className="auth-section">
      <ReactTitle title="login"/>
		<Container>
			<Row className='justify-content-center pt-5'>
				<Col md={7} lg={6} xl={5} >
        <Form className=' bg-gradient text-white rounded-3 p-4 p-md-5' onSubmit={(e)=>SubmitHandler(e)}>
            <h2>Login</h2>
            <FormGroup controlId="email" >
                <Label>Email</Label>
                <Input  name="email" type="email" 
                                placeholder="Your email" 
                               onChange={(e)=>ChangeHandler(e)}/>
            </FormGroup>

            <FormGroup controlId="password">
                <Label>Password</Label>
                <Input name="password"  type="password"
                 placeholder="password"
                 onChange={(e)=>ChangeHandler(e)}/>
            </FormGroup>

              <Button className="btn btn-dark w-100 mt-3 fs-5 w-100"  variant="hidden" type="submit">Login</Button>
              <p className="mt-2">Already have a account
                                  <span><Link to="/register" className=' px-2 text-dark'>register</Link></span>
              </p>
            
        </Form>				
				</Col>
			</Row>
		</Container>
		</section> 
  )
}

export default Login